<template>
  <div class="page app">
    <div>
      <Header/>
      <div class="page__wrapper">
        <Aside/>
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>

import Aside from '../components/Aside'
import Header from '../components/Header'
export default {
  name: 'DefaultContainer',
    components: {
      Header,
      Aside
    },
    mounted() {
      menu.init()
      menuMobile.init()
      aside.init()
      moduleResellerOwner.init();
      moduleResellerSafe.init();
    }
}
</script>